<template>
  <h3 class="heading-3">
    {{ i18n(translations.title) }}
  </h3>
  <RevCard>
    <div v-if="paymentErrorMessage" ref="errorMessageBlock" class="flex">
      <RevInfoBlock
        class="mx-20 mb-20 mt-24"
        dismissable
        :icon="IconHeartExploded"
        :title="paymentErrorMessage.title"
        variant="warning"
      >
        <div class="body-2 mt-8">
          <p>{{ paymentErrorMessage.description }}</p>
        </div>
      </RevInfoBlock>
    </div>
    <PaymentForm
      :base-price="totalPrice"
      :create-payment="refreshPayment"
      :pass-through-on-zero-price="true"
      :payment-methods="paymentMethods || []"
      :resume-payment-id
      @setup-error="handleSetupError"
      @submit-error="handleSubmitError"
      @submit-success="handleSubmitSuccess"
      @update:selected-method="handleMethodChange"
    />
  </RevCard>
</template>

<script setup lang="ts">
import { useRequestURL, useRoute, useRouter } from '#imports'
import { computed, ref } from 'vue'

import { type MonetaryAmount } from '@backmarket/http-api'
import { postRefresh } from '@backmarket/http-api/src/api-specs-payment/payment/payment'
import {
  PaymentForm,
  type PaymentFormCreateFunctionOptions,
  type PaymentReadableMessage,
  type SetupErrorEvent,
  type SubmitErrorEvent,
  type SubmitSuccessData,
  toCheckoutPaymentCreateBody,
  usePaymentRedirection,
} from '@backmarket/nuxt-layer-payment/form'
import { useMarketPaymentMethodsFor } from '@backmarket/nuxt-layer-payment/methods'
import { $httpFetch } from '@backmarket/nuxt-module-http/$httpFetch'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevCard } from '@ds/components/Card'
import { RevInfoBlock } from '@ds/components/InfoBlock'
import { IconHeartExploded } from '@ds/icons/IconHeartExploded'

import { INSURANCE } from '~/scopes/insurance/routes.constants'

import translations from './Payment.translations'

const props = defineProps<{
  policyId: string
  totalPrice: MonetaryAmount
}>()

const paymentErrorMessage = ref<PaymentReadableMessage | null>(null)
const errorMessageBlock = ref<HTMLInputElement | null>()

const route = useRoute()
const router = useRouter()
const { origin } = useRequestURL()

const i18n = useI18n()

const resumePaymentId = computed(() =>
  route.query.paymentId ? String(route.query.paymentId) : undefined,
)

const { data: paymentMethods } = await useMarketPaymentMethodsFor({
  bagPrice: () => props.totalPrice,
  hasInsurance: true,
  tokenization: true,
})

const proceedRedirection = usePaymentRedirection({
  paymentResultLocation: (paymentId) => ({
    name: INSURANCE.DUE_PAYMENT_RESULT,
    params: { policyId: props.policyId },
    query: { paymentId },
  }),
})

const refreshPayment = async (options: PaymentFormCreateFunctionOptions) => {
  const successUrl = router.resolve({
    name: INSURANCE.DUE_PAYMENT_RESULT,
    params: { policyId: props.policyId },
  }).fullPath

  const cancelAndFailureUrl = router.resolve({
    name: INSURANCE.DUE_PAYMENT,
    params: { policyId: props.policyId },
  }).fullPath

  const paymentResponse = await $httpFetch(postRefresh, {
    pathParams: {
      policyId: props.policyId,
    },
    body: {
      ...toCheckoutPaymentCreateBody(options),
      successUrl: `${origin}${successUrl}`,
      failureUrl: `${origin}${cancelAndFailureUrl}`,
      cancelUrl: `${origin}${cancelAndFailureUrl}`,
    },
  })

  return paymentResponse
}
const showErrorMessage = (message: PaymentReadableMessage) => {
  paymentErrorMessage.value = message
  errorMessageBlock.value?.scrollIntoView({ behavior: 'smooth' })
}

const handleSetupError = async ({ message }: SetupErrorEvent) => {
  showErrorMessage(message)
}

const handleSubmitError = async ({
  redirection,
  message,
}: SubmitErrorEvent) => {
  if (redirection) {
    proceedRedirection(redirection)

    return
  }
  if (message) showErrorMessage(message)
}

const handleSubmitSuccess = ({ redirection }: SubmitSuccessData) => {
  proceedRedirection(redirection)
}

const handleMethodChange = (_: unknown, isSelectedByUser: boolean) => {
  if (isSelectedByUser) {
    paymentErrorMessage.value = null
  }
}
</script>
